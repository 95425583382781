import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Visuelle Inhalte sind großartig! Bilder vermitteln Emotionen, beflügeln unsere Fantasie und machen eine
Website ästhetisch ansprechend. Allerdings können viele Menschen Web-Inhalte nicht visuell wahrnehmen.
Blinde und sehbeeinträchtigte Nutzer:innen sind darauf angewiesen, dass Web-Entwickler:innen und
Content-Editor:innen einen Alternativtext bereitstellen.`}</p>
    <p>{`Leider gibt es immer noch sehr viele Websites mit Bildern, für die kein Alternativtext gesetzt ist oder deren
Alternativtext irreführend ist. Stellt euch vor, ihr besucht eine Website und statt Fotos und Grafiken seht ihr
nur leere Flächen und verschwommene Bilder. Nervig, oder?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBf/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAeFSRXVkaf/EABoQAAICAwAAAAAAAAAAAAAAAAABETECEBL/2gAIAQEAAQUCIx6caV2f/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEhgf/aAAgBAwEBPwFOmH//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAXEAEAAwAAAAAAAAAAAAAAAAABABEg/9oACAEBAAY/AoU5/8QAGxABAAICAwAAAAAAAAAAAAAAAQAREDEhQVH/2gAIAQEAAT8hm0k7sgLW48OCvU//2gAMAwEAAgADAAAAEF8P/8QAGBEBAAMBAAAAAAAAAAAAAAAAAQARIaH/2gAIAQMBAT8Qo0RVbByf/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAECAQE/EKuP/8QAGhABAQEAAwEAAAAAAAAAAAAAAREAITFBUf/aAAgBAQABPxBfPJmC0OUo6XHXu41PmaCZsvDzf//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ein dunkler Raum mit Neonlicht-Schrift an der Wand. Der Text lautet: 'Nichts zu sehen hier'.",
          "title": "Ein dunkler Raum mit Neonlicht-Schrift an der Wand. Der Text lautet: 'Nichts zu sehen hier'.",
          "src": "/static/b9ace731ed3ca8206da184a9358c04cb/e5166/pexels-aleksandar-pasaric-nothing-to-see.jpg",
          "srcSet": ["/static/b9ace731ed3ca8206da184a9358c04cb/f93b5/pexels-aleksandar-pasaric-nothing-to-see.jpg 300w", "/static/b9ace731ed3ca8206da184a9358c04cb/b4294/pexels-aleksandar-pasaric-nothing-to-see.jpg 600w", "/static/b9ace731ed3ca8206da184a9358c04cb/e5166/pexels-aleksandar-pasaric-nothing-to-see.jpg 1200w", "/static/b9ace731ed3ca8206da184a9358c04cb/b17f8/pexels-aleksandar-pasaric-nothing-to-see.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Aleksandar Pasaric / pexels.com`}</em></p>
    <p>{`In diesem Artikel werde ich einige Beispiele für schlechten Alternativtext teilen, auf die ich regelmäßig bei
Barrierefreiheits-Prüfungen stoße. Doch als erstes sehen wir uns die Grundlagen an.`}</p>
    <h2>{`Die Grundlagen barrierefreier Bilder`}</h2>
    <p>{`Meistens verwenden wir das HTML-Element `}<InlineCode mdxType="InlineCode">{`img`}</InlineCode>{` um Bilder auf einer Website anzuzeigen.
Mit dem Attribut `}<InlineCode mdxType="InlineCode">{`alt`}</InlineCode>{` können wir einen Alternativtext für das Bild definieren.
Die `}<a parentName="p" {...{
        "href": "https://html.spec.whatwg.org/#the-img-element"
      }}>{`HTML-Spezifikation`}</a>{` beschreibt das Attribut so:`}</p>
    <blockquote lang="en">
    alt — Replacement text for use when images are not available
    </blockquote>
    <p>{`Das bedeutet, dass der Alternativtext auf der Seite angezeigt wird, wenn das Bild z.B. wegen Netzwerkfehlern nicht
geladen werden kann. Natürlich ist der Text noch wichtiger für die Barrierefreiheit. Screenreader lesen den
Alternativtext ihren Nutzer:innen vor, so dass sie wissen, was auf dem Bild zu sehen ist.`}</p>
    <p>{`Es gibt noch andere Wege, um Bilder in einer Webseite einzubinden: Etwa mit dem HTML-Element `}<InlineCode mdxType="InlineCode">{`svg`}</InlineCode>{`
oder den CSS-Pseudo-Elementen `}<InlineCode mdxType="InlineCode">{`:before`}</InlineCode>{` und `}<InlineCode mdxType="InlineCode">{`:after`}</InlineCode>{`. Um mehr über die
barrierefreie Aufbereitung dieser Elemente zu erfahren, folgt den Links im Abschnitt „Nützliche Links“ unten.`}</p>
    <h2>{`Den passenden Alternativtext formulieren`}</h2>
    <p>{`Bei allen Bild-Elementen stellt sich dieselbe Frage: Welcher Alternativtext ist für das Bild angemessen? Das hängt
vom Typ des Bildes ab:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Informative Bilder`}</strong>{`: Bilder, die relevante Informationen vermitteln. Die Textalternative sollte die Bedeutung bzw.
die relevanten Details des visuell wahrnehmbaren Inhalts vermitteln.`}
        <ul parentName="li">
          <li parentName="ul">{`Es gibt `}<a parentName="li" {...{
              "href": "https://css-tricks.com/just-how-long-should-alt-text-be/"
            }}>{`verschiedene Meinungen`}</a>{` darüber, ob der
Alternativtext aus einem kurzen, prägnanten Satz oder einer detaillierten Beschreibung in mehreren Sätzen bestehen soll.`}</li>
          <li parentName="ul">{`Bei komplexen Bildinhalten (z.B. Diagramme) sollte der Alternativtext kurz gehalten werden und auf eine lange
Beschreibung verweisen, die auf das Bild folgt.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Dekorative Bilder`}</strong>{`: Bilder, die dem Inhalt einer Seite keine relevanten Informationen hinzufügen. Dies ist etwa bei
Bildern der Fall, die eine Seite nur optisch attraktiver machen sollen. Oder wenn der Informationsgehalt eines Bildes
bereits durch nebenstehenden Text vermittelt wird. Diese Bilder solltet ihr vor assistiven Technologien verbergen,
z.B. indem ihr einen leeren Alternativtext setzt (`}<InlineCode mdxType="InlineCode">{`alt=""`}</InlineCode>{`).`}</li>
      <li parentName="ul"><strong parentName="li">{`Funktionale Bilder`}</strong>{`: Bilder, die in Schaltflächen, Links und anderen interaktiven Elementen verwendet werden. In
diesem Fall sollte der Alternativtext den Zweck des interaktiven Elements vermitteln und nicht das Bild selbst beschreiben.`}</li>
    </ul>
    <h2>{`Meine persönlichen Top 5 schlechter Alternativtexte`}</h2>
    <p>{`Bei Barrierefreiheits-Prüfungen von Websites stoße ich regelmäßig auf die immer gleichen Fehler. Hier ist meine
persönliche Liste an Beispielen für miserablen Alternativtext:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Titel in Dauerschleife`}</strong>{`: Eine Artikelvorschau (z.B. im Nachrichten-Karussell) beinhaltet meistens ein
Vorschau-Bild, um den Artikel zu illustrieren. Sehr oft finde ich Alternativtext vor, der nicht den Inhalt des Bildes
beschreibt, sondern den Titel des Artikels wiederholt. Das ist völlig redundant und eine Zeitverschwendung für
Screenreader-Nutzer:innen. Bitte nehmt euch die Zeit, den tatsächlichen Inhalt des Bildes zu beschreiben!`}</li>
      <li parentName="ol"><strong parentName="li">{`Hat der Fotograf ein Selfie gemacht?`}</strong>{` Oft treffe ich auf Fotos, deren Alternativtext die Copyright-Information
beinhaltet. Hat die Fotografin ein Selfie gemacht und ist auf dem Foto zu sehen? Nein? Dann schmeiß den Namen aus dem
Alternativtext raus! Du kannst das Copyright in die sichtbare Bildbeschreibung geben.`}</li>
      <li parentName="ol"><strong parentName="li">{`Was tut das Ding?`}</strong>{` Ein weiterer häufiger Fehler ist deskriptiver Alternativtext für funktionale Bilder. Stellt
euch eine Icon-Schaltfläche vor, die visuell als nach unten zeigender Pfeil dargestellt wird. Die Schaltfläche ermöglicht
das Herunterladen eines Dokuments, aber der Alternativtext lautet „Pfeil nach unten“. Nicht der beste Weg, um den
Zweck der Schaltfläche zu vermitteln.`}</li>
      <li parentName="ol"><strong parentName="li">{`Ich bin gar nicht da`}</strong>{`: Man nehme ein `}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{` Element und packe ein Bild darauf mithilfe der
CSS-Eigenschaft `}<InlineCode mdxType="InlineCode">{`background-image`}</InlineCode>{`. `}<span lang="fr">{`Et voilà!`}</span>{` Ihr könnt das Bild sehen.
Aber es gibt keine Informationen für assistive Technologien. Screenreader-Nutzer:innen werden gar nicht bemerken, dass
ein Bild zu sehen ist. Oder noch schlimmer: Sie navigieren zu einem Bildlink oder einer Icon-Schaltfläche und erhalten
keine Info über deren Zweck. Danke für nichts!`}</li>
      <li parentName="ol"><strong parentName="li">{`Reine Faulheit`}</strong>{`: Traurig, aber wahr. Ich treffe immer wieder auf `}<InlineCode mdxType="InlineCode">{`img`}</InlineCode>{` Tags, für die gar
kein `}<InlineCode mdxType="InlineCode">{`alt`}</InlineCode>{` Attribut definiert ist. Es gibt einfach keine Beschreibung des Bildes. In diesem Fall
lesen Screenreader häufig den Dateinamen des Bildes vor. Nicht gerade hilfreich, wenn das Bild als „wdkmm12xpY5.png“
beschrieben wird.`}</li>
    </ol>
    <h2>{`Fazit`}</h2>
    <p>{`Wenn ihr visuelle Inhalte in eine Webseite einbaut, dann stellt euch folgende Fragen: Welchen Zweck erfüllt das Bild?
Ist es rein dekorativ? Falls nicht, definiert einen sinnvollen Alternativtext, der das Bild beschreibt oder den Zweck
des Links oder der Schaltfläche vermittelt.`}</p>
    <h2>{`Nützliche Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/tutorials/images/"
        }}>{`W3C Images Tutorial`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/tutorials/images/decision-tree/"
        }}>{`An alt Decision Tree`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://css-tricks.com/accessible-svgs/"
        }}>{`Accessible SVGs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://adrianroselli.com/2020/10/alternative-text-for-css-generated-content.html"
        }}>{`Alternative Text for CSS Generated Content`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      